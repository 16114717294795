<template>
  <div></div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.text-classroom-hover:hover {
  font-weight: bold;
}

.text-classroom-hover:hover .text-primary {
  color: #ff6000 !important;
}

.text-classroom-hover:hover .text-dark-75 {
  color: #ff6000 !important;
}

.text-custom {
  color: #ff6000;
}

.icon-size {
  font-size: 2rem;
}
</style>