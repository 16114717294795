<template>
  <div>
    <h3 class="p-2 p-lg-3 my-1 my-lg-3 text-primary" style="cursor: pointer">
      Tenant
    </h3>
    <!--begin::List-->
    <template>
      <router-link to="/registrants">
        <div class="list list-hover bg-custom">
          <div class="hoverable-custom p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-custom mb-0 font-wight-bold">
                  Pendaftar
                </span>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
      <!-- <router-link to="/registrants/add">
        <div class="list list-hover bg-custom">
          <div class="hoverable-custom p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Tambah Pendaftar
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link> -->
      <router-link to="/billings">
        <div class="list list-hover bg-custom">
          <div class="hoverable-custom p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="fas fa-file-invoice-dollar text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-custom mb-0 font-wight-bold">
                  Billing
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/tenants">
        <div class="list list-hover bg-custom">
          <div class="hoverable-custom p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="fas fa-network-wired text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-custom mb-0 font-wight-bold">
                  Daftar Tenant
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/databases">
        <div class="list list-hover bg-custom">
          <div class="hoverable-custom p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="fas fa-database text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-custom mb-0 font-wight-bold">
                  Database
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </template>
    <!--end::List-->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import api from "@/core/services/api.service.js";
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.router-link-active .bg-custom{
  background: #ff6000;
}

.router-link-active .text-custom{
  color: white !important;
}

.hoverable-custom:hover{
  background: #ff6000;
}

.hoverable-custom:hover .text-custom{
  color: white;
}

.icon-size{
  font-size: 22pt;
}
</style>